
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/base/animations.scss';
                








































































































.c-banner {
    z-index: 50;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;

    .c-accordion {
        border-top: 0;

        &.is-open {

            .c-banner__deco {
                transform: translate(100%, 0) scale(1.2);
            }
        }
    }

    .c-accordion__header {
        position: static;
        padding: .75em 1em;

        &:after {
            display: none;
        }
    }

    .c-accordion__title {
        display: none;
    }

    &.is-hidden {
        transform: translate(0, 100%);
        transition: transform .8s $in-out-circ;

        .c-banner__close {
            transform: scale(0);
            transition: transform .3s $in-quad;
        }
    }

    @media #{md("sm", "max")} {

        .c-accordion__btn {
            display: none;
        }

        .c-accordion__header {
            padding-top: 1em;
            padding-bottom: 1em;
            justify-content: center;
            pointer-events: none;
        }

        .c-accordion__container {
            height: auto;
            opacity: 1;
        }

        .c-accordion__content {
            padding: 0 1em 1em;
            text-align: center;
            pointer-events: initial;
        }
    }
}

.c-banner__close {
    z-index: 1;
    position: absolute;
    top: -1em;
    left: 0;
    font-size: .9rem;

    .o-round {
        --color-bg: #{$color-blue};
        --color-border: #{$color-blue};

        &:hover:before {
            --color-bg: #{$color-blue};
        }
    }

    .o-icon--plus {
        transform: rotate(45deg);
    }
}

.c-banner__inner {
    background-color: $color-light;
    box-shadow: $shadow;
}

.c-banner__title {
    margin-bottom: .75em;

    @media #{md("sm", "max")} {
        display: none;
    }
}

.c-banner__desc {
    margin-bottom: 2em;

    @media #{md("sm", "max")} {
        display: none;
    }
}

.c-banner__btn {

    @media #{md("sm")} {
        margin-bottom: 2em;
    }
}

.c-banner__deco {
    position: absolute;
    bottom: 0;
    right: 11em;
    display: block;
    @include img(7em);
    pointer-events: none;
    transform-origin: 50% 100%;
    transition: transform 1s $in-out-quint;

    @media #{md("sm", "max")} {
        display: none;
    }

    @media #{md("lg")} {
        width: 8em;
    }
}

