
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/base/animations.scss';
                




























































.o-anim-text {
    --delay: 0;

    display: flex;
    flex-wrap: wrap;
    margin-right: -.15em;
    margin-left: -.15em;

    &.is-visible,
    .js-reveal.is-visible & {
        @include anim-text-in;
    }
}

.o-anim-text__w {
    height: 1.2em;
    margin: calc(-.2em/2) .15em;
    overflow: hidden;
}

.o-anim-text__b {
    display: block;
    width: 100%;
    height: 1em;
}

.o-anim-text__t {
    display: block;
    transition: inherit;

    .js-reveal & {
        opacity: 0;
        transform: translate(0, 110%);
    }
}

