/*=================================
=            Selection            =
=================================*/


::-moz-selection {
	text-shadow: none;
	color: $selection-color;
	background: $selection-bg;
}

::selection {
	text-shadow: none;
	color: $selection-color;
	background: $selection-bg;
}



/*=======================================
=            Global settings            =
=======================================*/


// :root {
//     // Root custom properties
//     --color-dark: #{ $color-dark};
//     --color-light: #{$color-light};
// }

*,
*:after,
*:before {
	position: relative;
	outline: none;
}


html {
    width: 100%;
	font-size: $font-size;
    overflow-x: hidden;

    // Media queries
    @media #{md("xs", "max")} {
        font-size: $font-size - 2;
    }

    @media #{md("lg")} {
        font-size: $font-size + 1;
    }

    @media #{md("xl")} {
        font-size: $font-size + 2;
    }
//
//    @media #{md("lg")} {
//    	font-size: $font-size + 3;
//    }
//
//    @media #{md("xl")} {
//    	font-size: $font-size + 4;
//    }
//
//    @media #{md("xxl")} {
//    	font-size: $font-size + 5;
//    }

    // Better font rendering for mac
    &.is-mac-os {
        @include antialiased;
    }
}

