
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/base/animations.scss';
                



































.o-round {
    --color-bg: #{$color-yellow};
    --color-border: #{$color-yellow};
    --size: 2em;

    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--size);
    height: var(--size);

    &:before {
        @include pseudo-el($bg: var(--color-bg));
        position: absolute;
        top: 0;
        left: 0;
        border: 0 solid var(--color-border);
        border-radius: 50%;
        background-color: var(--color-bg);
        transition: all .3s ease-out;
    }

    &--md {
        --size: 2.5em;
    }

    &--lg {
        --size: 4em;

        .o-icon {
            font-size: 1.3em;
        }
    }

    .o-icon--arrow-right {
        left: .05em;
    }

    .o-icon--arrow-left {
        right: .05em;
    }
}

