
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/base/animations.scss';
                












































































































































































.l-footer {
    margin-top: 10vh;
}

.l-footer__logo {
    display: inline-block;

    span {
        display: block;
        margin-top: -.4em;
    }
}

.l-footer__partners {
    margin-top: 2em;

    ul {
        @include reset-list;
        display: flex;
    }

    li {
        flex: 1 1 0;
    }

    span {
    }

    img {
        @include img;
        max-width: 12em
    }

    a {
        display: block;
        cursor: pointer;
        transition: transform .4s ease-out;

        &:hover {
            transform: scale(1.02);
        }
    }
}

.l-footer__partners-title {
    display: block;
    max-width: calc(50% + 12em);
    margin-bottom: 1.5em;
    font-weight: 700;
}

.l-footer__socials {
    @include reset-list;
    display: flex;
    width: 100%;
    margin-top: 3em;
    margin-bottom: 2em;
    border-top: $border;
    border-bottom: $border;

    li {
        flex: 1 1 0;
        padding-top: .75em;
        padding-bottom: .75em;
        text-align: center;

        &:nth-child(n+2) {
            border-left: $border;
        }
    }

    a {
        position: static;
        display: inline-flex;
        align-items: center;
        transition: color .4s ease-out;

        &:before {
            @include pseudo-el($width: auto, $height: auto, $bg: $color-dark);
            position: absolute;
            top: -1px;
            right: -1px;
            bottom: -1px;
            left: -1px;
            transform: scale(1, 0);
            transform-origin: 50% 0;
            transition: transform .4s ease-out;
        }

        &:hover {
            color: $color-light;

            &:before {
                transform: scale(1);
                transform-origin: 50% 100%;
            }
        }
    }

    span {
        display: inline-block;
        margin-left: .75em;
        font-weight: 700;
    }

    @media #{md("sm")} {
        margin-bottom: 4em;
    }
}

.l-footer__bottom {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 7vh;
    font-size: .875em;

    @media #{md("xs", "max")} {

        > * {
            margin-bottom: .5rem;
        }
    }

    @media #{md("sm")} {
        margin-bottom: 1rem;
    }
}

.l-footer__copy {
    display: inline-block;
    margin-right: 2em;
}

.l-footer__links {
    @include reset-list;
    margin: 2em -1em 1em;
    display: block;

    li {
        display: inline-block;
        margin-right: 1em;
        margin-left: 1em;
    }
}

.l-footer__btn {
    z-index: 1;

    @media #{md("sm", "max")} {
        --btn-width: 50vw;

        margin-bottom: -20%;
        left: 25%;
    }

    @media #{md("sm")} {
        --btn-width: 25vmax;

        position: absolute;
        bottom: calc(-1 * var(--btn-width)/4);
        left: calc(50% - var(--btn-width)/2);
    }
}

.l-footer__deco {

    .o-shape {

        &--square {
            --width: 14vmax;
            transform: rotate(15deg);
        }

        &--line {
            --width: 14vmax;
            transform: rotate(-70deg);
            transform-origin: 0 50%;
        }
    }

    @media #{md("sm", "max")} {
        //overflow: hidden;

        .o-shape {

            &--square {
                --width: 30vw;
                bottom: .5vw;
                left: calc(50% - var(--width)/2);
                margin-top: 3vw;
                transform: rotate(10deg);
            }

            &--line {
                --width: 35vw;
                position: absolute;
                top: 0;
                right: 77.75%;
                transform: rotate(-50deg);
                transform-origin: 100% 0;
            }
        }
    }

    @media #{md("sm")} {
        position: absolute;
        bottom: 0;
        left: 0;
        width: calc(4/12 * 100%);

        .o-shape {

            &--square {
                position: absolute;
                bottom: calc(25vmax * 3/4);
                left: calc(50% - var(--width)/2 + 2.5vmax);
                transform: rotate(15deg);
            }

            &--line {
                position: absolute;
                left: calc(-1 * var(--width)/6);
                bottom: calc(-1 * var(--width)/6);
            }
        }
    }

    @media #{md("xxl")} {
        width: calc(4.5/12 * 100%);
    }
}

