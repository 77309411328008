/*============================
=            Grid            =
============================*/

// Container
.grid {
    width: $layout-main--width;
    max-width: $layout-main--max-width;
    margin-right: auto;
    margin-left: auto;
}

// Row
.grid__row {
    display: flex;
    flex-wrap: wrap;

    // Media queries
    @each $breakpoint in $breakpoints {
        $key: nth($breakpoint, 1);
        $grid-gutter: map-get($grid-gutters, $key);

        @media #{md($key)} {
            margin-right: $grid-gutter * -0.5;
            margin-left: $grid-gutter * -0.5;
        }
    }
}


// Grid and cols
.grid,
[class^="w-"],
[class*=" w-"] {
    min-height: 1px;

    // Media queries
    @each $breakpoint in $breakpoints {
        $key: nth($breakpoint, 1);
        $grid-gutter: map-get($grid-gutters, $key);

        @media #{md($key)} {
            padding-right: $grid-gutter * 0.5;
            padding-left: $grid-gutter * 0.5;
        }
    }
}


// Generate all columns classes
@for $i from -$grid--columns through -1 {
    $i: -$i;

    .w-#{$i}\/#{$grid--columns} {

        @include responsive-class(false) {
            width: $i * 100% / $grid--columns
        }
    }

    .--offset-#{$i} {

        @include responsive-class(false) {
            margin-left: $i * 100% / $grid--columns
        }
    }

    .--push-#{$i} {

        @include responsive-class(false) {
            left: $i * 100% / $grid--columns
        }
    }
}


.--gutter {

    // Media queries
    @each $breakpoint in $breakpoints {
        $key: nth($breakpoint, 1);
        $grid-gutter: map-get($grid-gutters, $key);

        @media #{md($key)} {
            &\@#{$key} {
                padding-right: $grid-gutter * 0.5;
                padding-left: $grid-gutter * 0.5;
            }
        }
    }
}

.--no-gutter {
    @include responsive-class(false) {
        padding-right: 0;
        padding-left: 0;
    }
}
