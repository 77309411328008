
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/base/animations.scss';
                
































































































.c-btn {
    display: inline-flex;
    align-items: center;
    text-align: left;
    cursor: pointer;

    &:hover {

        .o-round:before {
            --color-bg: transparent;
            border-width: .2em;
            border-radius: 0;
            transform: rotate(135deg) scale(.95);
        }
    }

    &:active {

        .o-round:before {
            border-radius: 50%;
            transform: rotate(180deg) scale(1.1);
            transform: all .1s;
        }
    }

    &--boxed,
    &--stroke {
        padding: .5em 2em;
        transition: all .4s ease-out;

        &:hover {
            color: $color-light;
            border-radius: 2.5em;

            .c-btn__bg:before {
                transform: scale(1);
                transform-origin: 50% 100%;
            }
        }
    }

    &--stroke {

        .c-btn__bg {
            border: 1px solid $color-dark;
        }
    }

    &--boxed {
        color: $color-light;

        .c-btn__bg {
            background-color: $color-blue;
        }

        .c-btn__label {
            z-index: 1;
        }

        &:hover {

        }
    }

    &.is-disabled {
        pointer-events: none;

        &.c-btn--boxed {
            color: $color-grey-dark;

            .c-btn__bg {
                background-color: $color-grey;
            }
        }
    }

    &.is-hidden {
        display: none;
    }
}

.c-btn__bg {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    transition: inherit;
    overflow: hidden;

    &:before {
        @include pseudo-el($bg: $color-dark);
        position: absolute;
        top: 0;
        left: 0;
        transform: scale(1, 0);
        transform-origin: 50% 0;
        transition: transform .4s ease-out;
    }
}

.c-btn__label {
    display: block;
    font-weight: 700;
    width: 100%;

    & + .c-btn__icon {
        margin-left: .75em;
    }
}

.c-btn__icon {
    flex-shrink: 0;

    & + .c-btn__label {
        margin-left: .75em;
    }
}

