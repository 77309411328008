
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/base/animations.scss';
                









































































































.l-section {
    --skew: 3deg;

    &:first-child {
        --skew: -3deg;

        .l-section__header {
            padding-top: 10vh;
            padding-bottom: 5vh;
            margin-top: 0;
            margin-bottom: 4em;

            .l-section__bg {
                top: -$header-height;

                &:after {
                    top: auto;
                    bottom: calc(-5em/2);
                }

                &.js-reveal {
                    transform: translate(0, -5vh);

                    &:after {
                        transform-origin: 50% 0;
                    }

                    &.is-visible {
                        transform: translate(0);
                    }
                }
            }

            .l-section__fig {
                margin-bottom: calc(-5vh - 4em + 5em/2);
                transform: rotate(var(--skew)) translate(0, 1em);
            }
        }
    }

    @media #{md("xl")} {

        &:after {
            --skew: 2deg;
        }

        &:first-child:after {
            --skew: -2deg;
        }
    }
}

.l-section__bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: auto;
    background-color: var(--color-bg);

    &.--bg-dark + * {
        color: $color-light;
    }

    &:after {
        @include pseudo-el($height: 5em, $bg: inherit);
        position: absolute;
        top: calc(-5em/2);
        left: 0;
        transform: skew(0, var(--skew));
    }

    &.js-reveal {
        background-color: $color-light;
        transform: translate(0, 5vh);

        &:after {
            transform-origin: 50% 100%;
            transform: scale(1, 0) skew(0);
        }

        &.is-visible {
            background-color: var(--color-bg);
            transform: translate(0);
            transition: all .6s ease-in;

            &:after {
                transform: scale(1) skew(0, var(--skew));
                transition: transform .4s ease-out .2s;
            }
        }
    }
}

.l-section__header {
    padding-top: 5vh;
    padding-bottom: 10vh;
    margin-top: 4em;

    .js-reveal {

        &.is-visible {
            @include anim-text-in(.4, .4);
        }
    }
}

.l-section__title {
    margin-bottom: .75em;
}

.l-section__return {
    margin-bottom: 1em;

    .o-round {
        --color-border: #{$color-dark};
        transition: all .4s ease-out;
    }

    &:hover {

        .o-round:before {
            --color-bg: transparent;
            border-width: .2em;
            border-radius: 0;
            transform: rotate(-135deg) scale(.95);
        }
    }

    &:active {

        .o-round:before {
            border-radius: 50%;
            transform: rotate(-180deg) scale(1.1);
        }
    }
}

.l-section__content {
    padding-top: 10vh;
    padding-bottom: 10vh;
}

.l-section__subsection {

    &:nth-child(n+2) {
        padding-top: 7vh;
    }

    .l-section__title {
        margin-bottom: 1.5em;
    }
}

.l-section__col {

    > *:nth-child(n+2) {
        margin-top: 1.5em;
    }

    &.js-reveal,
    .js-reveal & {
        --sectionDelay: 0s;

        img {
            opacity: 0;
            transform: scale(.9);
            transform-origin: 50% 100%;
        }

        > .c-btn,
        .c-block-links {
            opacity: 0;
        }

        .o-shape {
            transform: scale(0) rotate(-45deg);
        }
    }

    &.js-reveal.is-visible,
    .js-reveal.is-visible & {

        > * {
            transition: all .3s ease-out;
        }

        img {
            opacity: 1;
            transform: scale(1);
        }

        > .c-btn,
        .c-block-links {
            opacity: 1;
        }

        .o-shape {
            transform: scale(1) rotate(0);
        }

        @for $i from 1 through 4 {

            > *:nth-child(#{$i}) {
                transition-delay: calc(var(--sectionDelay, 0s) + #{$i}s/6)
            }
        }
    }

    @media #{md("md")} {

        &.js-reveal,
        .js-reveal & {

            &:nth-child(2n) {
                --sectionDelay: .2s;
            }
        }
    }
}

.l-section__fig {
    margin-top: 2em;
    width: 100%;

    img {
        @include img;
        max-width: 42em;
        margin-right: auto;
        margin-left: auto;
    }
}

