
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/base/animations.scss';
                













































.c-block-links {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 5vh;

    @media #{md("xs", "max")} {
        flex-direction: column;
    }
}

.c-block-links__link {
    width: calc(50% - .5em);

    .js-reveal & {

    }

    .js-reveal.is-visible & {

    }

    &:before {
        @include pseudo-el($height: 1px, $bg: currentColor);
        position: absolute;
        bottom: 0;
        left: 0;
        transition: width .3s ease-out;
    }

    &:hover {

        &:before {
            width: calc(100% - 2.5em);
        }
    }

    @media #{md("xs", "max")} {
        width: 100%;

        &:nth-child(n+2) {
            margin-top: 1em;
        }
    }

    @media #{md("xs")} {
        min-width: 16em;
    }
}

