
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/base/animations.scss';
                









































































.l-header {
}

.l-header__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: $header-height;
}

.l-header__logo {
    z-index: 50;
    position: static;

    &:before {
        @include pseudo-el($height: 1px, $bg: $color-dark);
        position: absolute;
        bottom: 0;
        left: 0;
    }

    @media #{md("xs", "max")} {

        .svg-logo-en,
        .svg-logo-fr {
            display: none;
        }
    }

    @media #{md("xs")} {

        .svg-logo-en,
        .svg-logo-fr {
            font-size: .7em;
        }

        .svg-logo-xs-en,
        .svg-logo-xs-fr {
            display: none;
        }
    }

    @media #{md("sm")} {

        .svg-logo-en,
        .svg-logo-fr {
            font-size: 1em;
        }
    }
}

.l-header__btn {
    --btn-width: 10em;

    z-index: 200;
    position: fixed;
    top: calc(-1 * var(--btn-width)/2 + 1em);
    left: calc(50% - var(--btn-width)/2);
    transition: transform .2s ease-out;

    &.is-reduced {
        transform: translate(0, -1.5em);
    }

    &:hover {

        .o-shape {
            transform: translate(0, -1em);
        }


        &.is-reduced {

            .o-shape {
                transform: translate(0, .5em);
            }

            /*
            &:after {
                opacity: 1;
            }
            */
        }
    }

    @media #{md("sm", "max")} {

        .o-icon {
            transition: margin-top .2s ease-out;
        }

        &.is-reduced {

            .o-icon {
                margin-top: .4em;
            }

            &:hover {

                .o-icon {
                    margin-top: 1em;
                }
            }
        }
    }

    @media #{md("sm")} {
        --btn-width: 12em;
    }
}

.l-header__lang {
    z-index: 50;
    display: block;

    &:after {
        @include pseudo-el($height: 2px, $bg: currentColor);
        position: absolute;
        bottom: 0;
        left: 0;
    }
}

