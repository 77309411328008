
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/base/animations.scss';
                




































.o-grid {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &:before {
        content: var(--rows)
    }
}

.o-grid__el {
    position: absolute;
    display: block;
    background-color: currentColor;

    &--col {
        top: 0;
        width: 1px;
        height: 100%;
    }

    &--row {
        left: 0;
        width: 100%;
        height: 1px;
    }
}

