
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/base/animations.scss';
                




































































































































.o-card {
    display: block;
    padding: 1em;
    cursor: pointer;
    overflow: hidden;

    &:before {
        @include pseudo-el($bg: $color-light);
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        transform: scale(0, 1);
        transform-origin: 100% 50%;
        transition: transform .4s $in-expo .1s, opacity .4s ease-in;
    }

    &:hover {

        &:before {
            opacity: 1;
            transform: scale(1);
            transform-origin: 0 50%;
            transition: transform .4s $out-expo 0s, opacity .4s ease-out;
        }
    }
}

.o-card__region {
    display: block;
    padding-top: .75em;
    padding-bottom: .75em;
    border-top: $border;
}

.o-card__title {
    line-height: 1.1;
}

.o-card__asset {
    display: block;
    width: 96%;
    height: 0;
    margin: 2em auto 1.5em;
    padding-top: 50%;
    background-position: 50% 50%;
    background-size: cover;
    transform: rotate(4deg);
}

.o-card__item {
    display: block;

    &--desc {
        margin-bottom: 1rem;
    }

    &--contributions {
        margin-top: 2rem;
    }

    &--finAdd {
        border-top: $border;
        margin-top: 2rem;
        padding-top: 1rem;
    }

    &--days {
        display: flex;
        align-items: center;

        .o-icon {
            margin-right: .5em;
        }
    }
}

.findAdd {

    &__headline {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        span {
            display: inline-block;
            padding-left: 0.5em;
        }
    }

    &__programs {
        margin-top: 0.25rem;

        li {
            display: inline-flex;
            align-items: center;
            margin-top: 0.5rem;
            margin-right: 0.75rem;

            line-height: 1;
            list-style-type: none;

            .icon {
                display: inline-block;
                width: 1.25em;
                margin-right: 0.35em;
                padding: 0.15em;
                background: #FFF;
                overflow: hidden;
                border-radius: 1em;
            }
        }
    }
}
.o-card__progress {
    display: block;
    width: 100%;
    height: 1em;
    margin-top: .5em;
    margin-bottom: .5em;
    background-color: $color-light;

    span {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-color: $color-yellow;
        transform: scaleX(0);
        transform-origin: 0 50%;
    }
}

.o-card__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1em;
    padding-top: .5em;
    padding-bottom: .5em;
    border-top: $border;
    border-bottom: $border;
}

