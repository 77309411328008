/*==========================================
=            Position & display            =
==========================================*/

// Display
.u-db   { @include responsive-class { display: block !important; }}
.u-dn   { @include responsive-class { display: none !important; }}
.u-dt   { @include responsive-class { display: table !important; }}
.u-dtc  { @include responsive-class { display: table-cell !important; }}
.u-dib  { @include responsive-class { display: inline-block !important; }}
.u-di   { @include responsive-class { display: inline !important; }}
.u-dh   { @include responsive-class { display: inherit !important; }}
.u-df   { @include responsive-class { display: flex !important; }}
.u-dfi  { @include responsive-class { display: flex-inline !important; }}
.u-dg   { @include responsive-class { display: grid !important; }}

// Clearfix
.u-cf { @include responsive-class { @include clearfix; }}

// Image
.u-img { @include img; }


// Flex align
.--item-start       { @include responsive-class { align-items: flex-start; } }
.--item-end         { @include responsive-class { align-items: flex-end; }}
.--item-center      { @include responsive-class { align-items: center; }}
.--item-baseline    { @include responsive-class { align-items: baseline; }}
.--item-stretch     { @include responsive-class { align-items: stretch; }}

// Flex justify
.--justify-start    { @include responsive-class { justify-content: flex-start; }}
.--justify-end      { @include responsive-class { justify-content: flex-end; }}
.--justify-center   { @include responsive-class { justify-content: center; }}
.--justify-between  { @include responsive-class { justify-content: space-between; }}
.--justify-around   { @include responsive-class { justify-content: space-around; }}

// Align self
.--self-auto    { @include responsive-class { align-self: auto; }}
.--self-start   { @include responsive-class { align-self: flex-start; }}
.--self-center  { @include responsive-class { align-self: center; }}
.--self-end     { @include responsive-class { align-self: flex-end; }}
.--self-stretch { @include responsive-class { align-self: stretch; }}

// Justify self
.--justify-self-center  { @include responsive-class { margin-right: auto; margin-left: auto; }}
.--justify-self-start   { @include responsive-class { margin-right: auto; margin-left: 0; }}
.--justify-self-end     { @include responsive-class { margin-right: 0; margin-left: auto; }}


/*==================================
=            Typography            =
==================================*/


// Alignement de texte
.--tac { @include responsive-class { text-align: center !important; }}
.--tal { @include responsive-class { text-align: left !important; }}
.--tar { @include responsive-class { text-align: right !important; }}


/*=====================================
=            Accessibility            =
=====================================*/


/**
 * A class to hide labels and stuff
 * for accessibility
 * @author Facebook Accessibility Team
 * @source https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
 */
.u-accessible-item {
    position: absolute;
    width: 1px;
    height: 1px;
    white-space: nowrap;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
}



/*==============================
=            Colors            =
==============================*/

.--bg-dark {
    --color-bg: #{$color-dark } !important;
    --color-text: #{$color-light} !important;
}

.--bg-light {
    --color-bg: #{$color-light } !important;
    --color-text: #{$color-dark} !important;
}

.--bg-grey {
    --color-bg: #{$color-grey } !important;
    --color-text: #{$color-dark} !important;
}

.--bg-blue {
    --color-bg: #{$color-blue } !important;
    --color-text: #{$color-dark} !important;
}

.--bg-red {
    --color-bg: #{$color-red } !important;
    --color-text: #{$color-dark} !important;
}

.--bg-yellow {
    --color-bg: #{$color-yellow } !important;
    --color-text: #{$color-dark} !important;
}



/*============================
=            Text            =
============================*/


.--underline {
    margin-bottom: .5em;
    padding-bottom: .5em;

    &:before {
        @include pseudo-el($height: 1px, $bg: currentColor);
        position: absolute;
        bottom: 0;
        left: 0;
    }
}
