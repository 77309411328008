
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/base/animations.scss';
                





























































































.o-btn-start {
    display: block;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;

    .o-shape {
        --width: var(--btn-width, 10em);
        align-items: flex-end;
        padding-top: 1em;
        padding-bottom: 1em;
        transition: all .4s ease-out;
        transform-origin: 50% 0;

        &:before {
            transition: all .4s ease-out;
            will-change: border-radius, transform;
        }

        &:after {
            @include pseudo-el($bg: $color-grey);
            position: absolute;
            top: 0;
            left: 0;
            border-radius: inherit;
            opacity: 0;
            transition: opacity .4s ease-out;
        }
    }

    .o-shape__inner span {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-weight: 700;
    }

    .o-icon {
        margin-top: 1em;
    }

    &.is-hovering {

        .o-shape:before {
            animation: blob .8s ease-out;
        }
    }

    &:active {

        .o-shape:before {
            transform: scale(1.1);
            transition: transform .1s;
        }

    }

    &--invert {

        .o-shape {
            align-items: center;
        }

        .o-shape__inner > span {
            flex-direction: column-reverse;
        }

        .o-icon {
            margin-top: -2em;
            margin-bottom: 1em;
        }

        &:hover {

            .o-shape {
                //transform: translate(0, -5%);
            }
        }
    }
}
