
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/base/animations.scss';
                




















































































































































































































































































































































































































































































/*====================================
=            Hero section            =
====================================*/

.p-hero {

    .l-section__col {
        z-index: 2;
    }

    @media #{md("sm", "max")} {

        .l-section__header {
            max-height: calc(100vh - #{$header-height});
        }
    }

    @media #{md("md")} {

        .l-section__header {
            z-index: 2;
        }
    }
}

.p-hero__animation {
    z-index: 1;
    margin-top: -20%;
    margin-bottom: calc(-10vh);

    .js-reveal & {
        opacity: 0;
        transform: scale(.98, .95);
        transform-origin: 50% 80%;
    }

    .js-reveal.is-visible & {
        opacity: 1;
        transform: scale(1);
        transition: all .4s ease-out .8s;
    }

    @media #{md("sm", "max")} {
        text-align: center;

        svg {
            width: auto !important;
            max-width: 100%;
            height: auto !important;
            max-height: 65vh;
        }
    }

    @media #{md("xs", "max")} {

        svg {
            max-height: 60vh;
        }
    }

    @media #{md("md")} {
        z-index: 100;
        margin-top: calc(-#{$header-height} - 10vh - 10%);

        svg {
            width: auto !important;
            height: auto !important;
            max-width: 100%;
            max-height: 100vh;
        }
    }
}

/*
.p-hero__img {
    @include img;
    margin-top: -20%;
    margin-bottom: calc(-10vh);

    .js-reveal & {
        opacity: 0;
        transform: scale(.98, .95);
        transform-origin: 50% 80%;
    }

    .js-reveal.is-visible & {
        opacity: 1;
        transform: scale(1);
        transition: all .4s ease-out .8s;
    }

    @media #{md("sm", "max")} {
        width: auto;
        max-height: 65vh;
        margin-right: auto;
        margin-left: auto;
    }

    @media #{md("xs", "max")} {
        max-height: 60vh;
    }

    @media #{md("md")} {
        z-index: 100;
        margin-top: calc(-#{$header-height} - 10vh - 10%);
        width: auto;
        max-width: 100%;
        max-height: 100vh;
    }
}
*/

/*
.p-hero__btn {
    position: absolute;
    bottom: calc(-5vh - 4em);
    right: 0;

    @media #{md("sm", "max")} {
        bottom: -5vh;
        right: 50%;
        width: 100%;
        max-width: 18em;
        text-align: center;
        transform: translate(50%, 0);
    }
}
*/


/*=====================================
=            Intro section            =
=====================================*/


.p-intro {
}

.p-intro__bg {
    --skew: -3deg;

    position: absolute;
    top: -15vh;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: var(--color-bg);
    transform: skew(0, var(--skew));

    .js-reveal & {
        background-color: $color-light;
        transform: translate(0, -5vh) skew(0, var(--skew));
    }

    .js-reveal.is-visible & {
        background-color: var(--color-bg);
        transform: translate(0) skew(0, var(--skew));
        transition: all .6s ease-in;
    }

    @media #{md("xl")} {
        --skew: -2deg;
    }
}

.p-intro__content {
    z-index: 2;
}

.p-intro__animation {
    z-index: 1;
    width: 90%;


    @media #{md("md", 'max')} {
        max-width: 22em;
        margin-top: -10vh;
        margin-bottom: -25vh;
    }

    @media #{md("md")} {
        position: absolute;
        left: 0;
        bottom: -15vh;
        width: 45%;
        max-width: $layout-main--max-width/2
    }
}

.p-intro__video {
    margin-bottom: 4em;

    @media #{md("sm", "max")} {
        margin-top: 1.5em;
    }

    @media #{md("md")} {
        margin-bottom: 6em;
    }
}

/*
.p-intro__img {
    z-index: 1;
    @include img(90%);

    @media #{md("md", 'max')} {
        max-width: 22em;
        margin-top: -10vh;
        margin-bottom: -25vh;
    }

    @media #{md("md")} {
        position: absolute;
        left: 0;
        bottom: -15vh;
        width: 45%;
        max-width: $layout-main--max-width/2
    }
}
*/


/*=====================================
=            Funds section            =
=====================================*/

.p-graph {
    display: flex;
    margin-top: 10vh;
    margin-bottom: 15vw;

    .o-shape {
        align-items: flex-start;
        justify-content: flex-start;

        &--rectangle {
            --width: 96vw;
            --rotate: 3.5deg;
            padding-top: calc(var(--width)/10);
            padding-left: calc(var(--width)/15);
            transform: rotate(var(--rotate));

            .o-shape__inner {
                transform: rotate(calc(-1 * var(--rotate)));
            }

            &:last-child {
                --rotate: -3.5deg;
            }
        }

        &--circle {
            --width: 50vw;
            position: absolute;
            right: 5%;
        }
    }

    .o-shape__inner {
        display: inline-block;
        width: 60%;
        padding-bottom: 1em;
        font-weight: 700;
        border-bottom: $border;
    }

    @media #{md("sm", "max")} {
        flex-direction: column;
        align-items: center;

        .o-shape {

            &--rectangle {
                height: calc(var(--width) * .7);

                &:last-child {
                    top: calc(var(--width)/17);
                }

                .o-shape__inner {
                    transform: rotate(var(--rotate));
                }
            }

            &--circle {
                top: 13.7%;
            }
        }
    }

    @media #{md("sm")} {
        justify-content: space-between;
        width: 100%;
        margin-bottom: 10vh;

        .o-shape {

            &--rectangle {
                --width: 46.7%;
                --rotate: -3.5deg;
                height: 0;
                padding-top: 45%;
                transform-origin: 0 0;

                &:last-child {
                    --width: 47.5%;
                    --rotate: 2deg;
                    transform-origin: 100% 0;
                }

                .o-shape__inner {
                    position: absolute;
                    top: calc(var(--width)/5);
                    left: calc(var(--width)/7);
                }
            }

            &--circle {
                --width: 35vw;
                right: 51%;
                bottom: 3.32%;
                align-items: center;
            }
        }
    }

    @media #{md("xl")} {

        .o-shape {

            &--circle {
                --width: 30em;
            }
        }
    }
}

.p-fund {

    &:first-child .p-fund__shape {
        transform: rotate(-5deg);
    }

    &:last-child .p-fund__shape {
        transform: rotate(5deg);
    }

    @media #{md("sm", "max")} {

        &:nth-child(n+2) {
            margin-top: 2em;
        }
    }

    @media #{md("md")} {

        &.js-reveal {

            &:nth-child(3n+2) {
                --sectionDelay: .2s;
            }

            &:nth-child(3n) {
                --sectionDelay: .4s;
            }
        }
    }
}

.p-fund__title {
    margin-top: .5em !important;
}


/*=====================================
=            Steps section            =
=====================================*/

.p-steps {
    margin-top: 10vh;
    margin-bottom: 10vh;
}

.p-steps__deco {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .o-shape {
        position: absolute;

        &--line {
            top: -10%;
            left: -10%;
            transform: rotate(60deg);
        }

        &--square {
            top: 7%;
            left: 0;
            transform: rotate(20deg) translate(-50%, 0);
        }

        &--circle {
            top: 4.5%;
            left: 5em;
        }
    }

    @media #{md("sm", "max")} {
        overflow: hidden;
    }

    @media #{md("sm")} {

        .o-shape {

            &--line {
                --width: 15vw;
                top: -8vw;
                left: -6vw;
                transform-origin: 0 100%;
            }

            &--square {
                --width: 18vw;
                top: 24.2vw;
                left: -5vw;
                transform: rotate(20deg);
            }

            &--circle {
                --width: 18vw;
                top: 18vw;
                left: 15.5vw;
            }
        }
    }
}

.p-steps__step {
    max-width: 45em;
    margin-right: auto;
    margin-left: auto;
    padding-top: 5vh;
    padding-bottom: 5vh;
}


/*========================================
=            Projects section            =
========================================*/

.p-project {
    overflow: hidden;

    .c-btn__label {
        white-space: nowrap;
    }
}

.p-project__heading {
    transform-origin: 50% 100%;
    transition: all .3s ease-out .35s;

    @media #{md("sm")} {

        &.is-hidden {
            opacity: 0;
            transform: scale(.98);
            transition: all .2s 0s;
        }
    }
}

.p-project__slider {

    .o-card {
        width: 90%;
        max-width: 22em;
        margin-right: .5em;
        margin-left: .5em;
    }

    .flickity-viewport {
        overflow: visible;
    }

    .flickity-prev-next-button {
        display: none;
    }
}

.p-project__nav {
    display: flex;
    justify-content: flex-end;
    text-align: right;

    button {
        display: block;
        cursor: pointer;

        &:nth-child(2) {
            margin-left: 1em;
        }

        &.is-disabled {
            pointer-events: none;
            opacity: .5;
        }

        &:hover {

            .o-round:before {
                --color-bg: transparent;
                border-radius: 0;
                border-width: .2em;
            }

            &:first-child .o-round:before {
                transform: rotate(-135deg) scale(.95);
            }

            &:last-child .o-round:before {
                transform: rotate(135deg) scale(.95);
            }
        }

        &:active {

            &:first-child .o-round:before {
                border-radius: 50%;
                transform: rotate(-180deg) scale(1.1);
                transform: all .1s;
            }

            &:last-child .o-round:before {
                border-radius: 50%;
                transform: rotate(180deg) scale(1.1);
                transform: all .1s;
            }
        }
    }

    @media #{md("sm", "max")} {
        top: -2.5em;
    }

    @media #{md("sm")} {
        margin-top: 5vh;
        margin-bottom: 2em;
    }
}



