/*=============================
=            Icons            =
=============================*/


.o-icon {
    display: inline-block;
    vertical-align: middle;

    svg {
        display: block;
        fill: currentColor;
    }

    // Fix play icon bug on iOS
    .is-ios &--play {

        svg {
            display: none;
        }

        &:after {
            content: "";
            @include triangle(right, .375em, .75em, currentColor);
        }
    }
}


/*=================================
=            SVG Sizes            =
=================================*/


// Logo
.svg-logo-fr {
    width: 11.385em;
    height: 3.22em;
}

.svg-logo-en {
    width: 11.5em;
    height: 3.22em;
}

.svg-logo-xs-fr {
    width: 3.89em;
    height: 2.9em;
}

.svg-logo-xs-en {
    width: 3.56em;
    height: 2.9em;
}

// Arrows
.svg-arrow-up,
.svg-arrow-down {
    width: 1.25em;
    height: .666em;
}

.svg-arrow-left,
.svg-arrow-right {
    width: .47em;
    height: .75em;
}

// Plus
.svg-plus {
    width: .9em;
    height: .9em;
}

// Cross
.svg-cross {
    width: 1.25em;
    height: 1.25em;
}

// Play
.svg-play {
    width: .375em;
    height: .75em;
}

// Clock
.svg-clock {
    width: 1em;
    height: 1em;
}

// Socials
.svg-facebook {
    width: .468em;
    height: .9em;
}

.svg-instagram {
    width: .9em;
    height: .9em;
}

.svg-twitter {
    width: .9em;
    height: .75em;
}


// Covid
.svg-covid19-fr,
.svg-covid19-en {
    width: 20em;
    height: 1.81em;
}
