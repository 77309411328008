
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/base/animations.scss';
                



































































.o-loader {
    z-index: 200;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    padding-right: 50%;
    pointer-events: none;
    //opacity: 0;
    background-color: $color-light;

    display: none;

    .is-loading & {
        pointer-events: initial;
    }
}

.o-loader__counter {
    @include responsive-type(32, 256, 320, 1960);
    font-weight: 700;
}

