/*==================================
=            Animations            =
==================================*/


@keyframes blob {
    0% {
        border-radius: 50%;
        transform: rotate(0);
    }
    50% {
        border-radius: 0;
    }
    100% {
        border-radius: 50%;
        transform: rotate(90deg);
    }
}


@keyframes background-color {
    0% {
        background-color: $color-red;
    }
    32.999% {
        background-color: $color-red;
    }
    33% {
        background-color: $color-yellow;
    }
    65.999% {
        background-color: $color-yellow;
    }
    66% {
        background-color: $color-blue;
    }
    99.999% {
        background-color: $color-blue;
    }
    100% {
        background-color: $color-red;
    }
}


.o-hover-text {
    display: block;

    &:after {
        @include pseudo-el($height: 2px, $bg: $color-dark);
        position: absolute;
        top: 100%;
        left: 0;
    }

    &:hover:after {
        animation: background-color 3s infinite;
    }
}
