/*=========================================
=            Typographic bases            =
=========================================*/

// Include fontface
@include fontfaces($fontfaces, $font-dir);

body {
    font-family: ff("sans");
    color: $font-color;
    line-height: $line-height;
}


/*==============================
=            Titles            =
==============================*/

.t-t1,
.t-t2,
.t-t1-bold,
.t-t2-bold {
    line-height: 1;
}

.t-t1,
.t-t2,
.t-t3,
.t-t4 {
    font-weight: 400;

    &-bold {
        font-weight: 700;
    }
}

.t-t1,
.t-t1-bold {
    font-size: 2.25em;

    @media #{md("md")} {
        font-size: 3.5em;
    }
}

.t-t2,
.t-t2-bold {
    font-size: 1.5em;

    @media #{md("md")} {
        font-size: 2.25em;
    }
}

.t-t3,
.t-t3-bold {
    font-size: 1.125em;

    @media #{md("md")} {
        font-size: 1.5em;
    }
}

.t-t4,
.t-t4-bold {
    font-size: 1.125em;
}



/*==========================================
=            CMS content styles            =
==========================================*/


.t-cms {

    p,
    ul,
    ol,
    blockquote {
        margin-bottom: $line-height * 1em;
    }

    h1,
    h2,
    h3,
    h4 {
        margin: 1.414em 0 0.5em;
        line-height: 1.2;
        font-weight: 700;
    }

    a {
        display: inline;
        width: 100%;
        text-decoration: underline;
        background-image: linear-gradient($color-yellow 0, $color-yellow 100%);
        background-repeat: no-repeat;
        background-size: 0 100%;
        transition: background-size .6s ease-out;

        &:hover {
            background-size: 100% 100%;
        }
    }

    ol,
    ul {
        padding-left: 1em;

        li {
            margin-bottom: .75em;
        }
    }

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }

    .js-reveal & {
        opacity: 0;
        transform: translate(0, 2em);
    }

    .js-reveal.is-visible & {
        opacity: 1;
        transform: translate(0);
        transition: all .3s ease-out calc(1s * var(--delay, 0));
    }
}


/*=====================================
=            Miscellaneous            =
=====================================*/


small {
    font-size: 0.75em;
}

sup {
    vertical-align: super;
    font-size: 0.75em;
}

a {
    color: inherit;
    text-decoration: none;
}

.t-info {
    margin-top: 1em;
    margin-bottom: 1em;
    padding-left: 1.2em;
    color: $color-grey-dark;

    &:before {
        @include pseudo-el($width: 2px, $height: calc(100% - .5em), $bg: currentColor);
        position: absolute;
        top: .25em;
        left: 0;
    }
}
