
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/base/animations.scss';
                































































































.c-modal {
    z-index: 150;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &:not(.anim-slide-enter-active) .c-modal__video {
        opacity: 1;
    }
}

.c-modal__bg {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: $color-light;
}

.c-modal__video {
    position: absolute;
    top: 50%;
    left: 50%;
    height: auto;
    width: auto;

    opacity: 0;
    overflow: hidden;
    transform: translate(-50%, -50%);

    @media #{md("sm")} {
        //top: calc(50% + 5.5em/2);
    }
}

.anim-slide-enter-active {
    transition: all 1.2s;

    .c-modal__bg {
        transition: transform .8s $in-out-quint;
    }

    .c-modal__video {
        opacity: 1;
        //transform: translate(-50%, -50%);
        transition: opacity .4s ease-out .6s, transform .4s $out-quad .6s;
    }
}

.anim-slide-leave-active {
    transition: all .8s;

    .c-modal__bg {
        transition: transform .8s $in-out-expo;
    }

    .c-modal__video {
        transition: opacity .4s ease-in, transform .4s $in-quad;
    }
}

.anim-slide-enter, .anim-slide-leave-to {

    .c-modal__bg {
        transform: translate(0, -105%) skew(0, 3deg);
    }

    .c-modal__video {
        opacity: 0;
        transform: translate(-50%, calc(-50% - 1em));
    }
}

