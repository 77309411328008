
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/base/animations.scss';
                
// Base
@import "base/variables";
@import "base/ress"; // {ress|normalize|reset}

// Tools
@import "tools/functions";
@import "tools/mixins";
@import "tools/helpers";
// Debug
// @import "tools/debug-outline";

/*! purgecss start ignore */

// Objects
@import "base/animations";
@import "objects/icons";

// Components
@import "components/btn";


// Layout
@import "layout/grid";
@import "layout/typography";
@import "layout/global";
@import "layout/main";
@import "layout/header";
// @import "layout/footer";

// Pages
// @import "views/home";

/*! purgecss end ignore */
