
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/base/animations.scss';
                



















































































































.o-step-form {}

.o-step-form__item {
    padding-top: 2em;
    padding-bottom: 2em;

    &:nth-child(n+2) {
        border-top: $border-light;
    }
}

.o-step-form__submit {
    width: 100%;
    margin-top: 1em;
    text-align: center;
}

.o-step-form__btns {
    display: flex;
    text-align: center;

    .c-btn {
        text-align: center;
    }

    @media #{md("sm", "max")} {
        flex-direction: column;

        .c-btn {
            margin-top: 1em;
            margin-bottom: 1em;
        }
    }

    @media #{md("sm")} {
        //align-items: center;

        .c-btn {
            width: calc(50% - 5em/2);
        }

        .o-step-form__or {
            display: inline-block;
            align-self: center;
            width: 5em;
        }
    }
}

// Inputs
.c-form__fieldset {
    display: inline-flex;
    width: auto;
    margin-top: 1em;
    text-align: center;
    border: $border;
}

.c-form__el {
    width: 6em;
    user-select: none;

    &:nth-child(n+2) {
        border-left: $border;
    }

    input {
        display: none;

        &:checked + label {
            color: $color-light;
            background-color: $color-dark;
        }
    }

    label {
        display: block;
        padding-top: .6em;
        padding-bottom: .6em;
        line-height: 1;
        font-weight: 700;
    }
}
