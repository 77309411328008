
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/base/animations.scss';
                









































.o-shape {
    --width: 8em;
    --color-bg: #{$color-blue};

    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--width);
    padding: calc(var(--width)/4) calc(var(--width)/5);
    color: var(--color-text);
    // background-color: var(--color-bg);

    &:before {
        @include pseudo-el($bg: var(--color-bg));
        position: absolute;
        top: 0;
        left: 0;
        border-radius: inherit;
    }

    // Shapes
    &--square,
    &--circle {
        height: var(--width);
    }

    &--circle {
        border-radius: 50%
    }

    &--rectangle {
        height: calc(var(--width) * 1.2);
    }

    &--line {
        width: calc(var(--width) * 2);
        height: calc(var(--width) * 0.2);
    }

    // Sizes
    &.--xs {
        --width: 2em;
    }
}

.o-shape__inner {
    z-index: 1;
    display: block;
    line-height: 1.1;
}

